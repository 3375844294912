import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './dialog-create-new-scene.html';
import { MetaUtility } from 'resources/services/meta_utility';
import { SceneInstance, SceneType } from "../../../../mmar-global-data-structure";
import { SceneInitiator } from 'resources/scene_initiator';
import { InstanceUtility } from 'resources/services/instance_utility';
import { GlobalDefinition } from 'resources/global_definitions';
import { GlobalRelationclassObject } from 'resources/global_relationclass_object';
import { GlobalClassObject } from 'resources/global_class_object';
import { Logger } from 'resources/services/logger';
import { generateUUID } from 'three/src/math/MathUtils';
import { bindable, EventAggregator } from 'aurelia';


@customElement(__au2ViewDef)
export class DialogCreateNewScene {

    @bindable tree = null;
    selectedSceneType: SceneType = null;
    newSceneInstance: { name: string, description: string } = { name: "", description: "" };
    selectedIndex = null;

    constructor(
        private metaUtility: MetaUtility,
        private sceneInitiator: SceneInitiator,
        private instanceUtility: InstanceUtility,
        private globalObjectInstance: GlobalDefinition,
        private globalClassObject: GlobalClassObject,
        private globalRelationclassObject: GlobalRelationclassObject,
        private logger: Logger,
        private eventAggregator: EventAggregator
    ) { }

    async attached() {
        //subscribe to event aggregator from this -> this.eventAggregator.publish('openCreateNewSceneInstanceDialog', { sceneType: sceneType });
        this.eventAggregator.subscribe('openCreateNewSceneInstanceDialog', async payload => { await this.setValuesFromPayload(payload); });
    }

    async createNewScene() {
        if (this.metaUtility.checkIfSceneType(this.selectedSceneType)) {
            const sceneType = this.selectedSceneType as SceneType;
            const sceneInstance = new SceneInstance(generateUUID(), sceneType.uuid);
            sceneInstance.name = this.newSceneInstance.name;
            sceneInstance.description = this.newSceneInstance.description;

            await this.sceneInitiator.sceneInit();
            await this.instanceUtility.createTabContextSceneInstance(sceneInstance);

            // check if new SceneInstances is from an importet sceneType
            for (const importSceneType of this.globalObjectInstance.importSceneTypes) {
                if (sceneInstance.uuid_scene_type === sceneType.uuid) {

                    let index = this.tree.findIndex((item: { uuid: string; }) => item.uuid === sceneType.uuid);
                    if (index === -1) {
                        this.logger.log(`SceneType with uuid ${sceneType.uuid} not found in tree`, "info");
                        continue;
                    }
                    if (this.tree[index].children === undefined) {
                        this.tree[index].children = [];
                    }
                    this.tree[index].children.push(sceneInstance);
                }
            }

            // set globalClassObject classes
            this.globalClassObject.initClasses()
            this.globalRelationclassObject.initRelationClasses();
            this.logger.log(`SceneInstance with name ${sceneInstance.name} created`, "info");
        }

        this.eventAggregator.publish('updateSceneGroup');
    }
    cancel() {
        this.logger.log('cancel button clicked', 'close');
    }

    onSelectionChange(event: CustomEvent) {
        this.selectedSceneType = event.detail.value;
        this.newSceneInstance.name = "new " + this.selectedSceneType.name;
        console.log(this.selectedIndex);
    }

    //set values from payload -> this is only used if a user makes a double click on a sceneType in the treeview and the dialog should be opened with the selected sceneType
    async setValuesFromPayload(payload) {
        this.selectedSceneType = payload["sceneType"] as SceneType;
        this.newSceneInstance.name = "new " + this.selectedSceneType.name;
        this.selectedIndex = this.selectedSceneType
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = DialogCreateNewScene;

    // @ts-ignore
    const proto = DialogCreateNewScene.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }