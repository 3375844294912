import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './main-body-tab-bar.html';
import { EventAggregator } from 'aurelia';
import { GlobalSelectedObject } from './../../resources/global_selected_object';
import { SceneInitiator } from 'resources/scene_initiator';
import { GlobalRelationclassObject } from './../../resources/global_relationclass_object';
import { GlobalClassObject } from "resources/global_class_object";
import { GlobalDefinition } from "../../resources/global_definitions";
import { InstanceUtility } from 'resources/services/instance_utility';
import { SceneType, SceneInstance } from '../../../../mmar-global-data-structure';
import * as THREE from 'three';
import { Logger } from 'resources/services/logger';

@customElement(__au2ViewDef)
export class MainBodyTabBar {

  selectedTab: number = 0; // Set the default active tab
  open: boolean = false;
  //selectedTabContext: {} = {};

  constructor(
    private globalObjectInstance: GlobalDefinition,
    private globalClassObject: GlobalClassObject,
    private globalRelationclassObject: GlobalRelationclassObject,
    private instanceUtility: InstanceUtility,
    private sceneInitiator: SceneInitiator,
    private globalSelectedObject: GlobalSelectedObject,
    private logger: Logger,
    private eventAggregator: EventAggregator
  ) { }



  // This function is called when a tab is clicked
  // It sets the selectedTab variable to the clicked tab
  // This variable is used to set the active class on the tab
  async clickedTab(selectedTab: number) {

    //remove boxHelper if it exists on old active scene
    this.globalSelectedObject.removeObject();

    this.selectedTab = selectedTab;

    // Set the selected tab context
    this.globalObjectInstance.selectedTab = this.selectedTab;
    this.eventAggregator.publish('tabChanged');

    let threeScene = await this.instanceUtility.getTabContextThreeInstance();

    if (threeScene) {
      this.globalObjectInstance.scene = threeScene;

      this.logger.log('Current Tab ' + this.selectedTab + ": name: " + this.globalObjectInstance.tabContext[selectedTab].sceneInstance.name, 'info');

      // set globalClassObject classes
      this.globalClassObject.initClasses()
      this.globalRelationclassObject.initRelationClasses();

      this.globalObjectInstance.dragObjects = this.globalObjectInstance.tabContext[this.selectedTab].contextDragObjects;
      //-------------------------------
      //set up controls
      //-------------------------------
      this.globalObjectInstance.scene.add(this.globalObjectInstance.mousePointer3d);

      //add transformcontrols to scene
      this.sceneInitiator.initTransformControls();

      this.globalObjectInstance.scene.add(this.globalObjectInstance.plane);

    }

  }

  closeTab(tab: { sceneType: SceneType; sceneInstance: SceneInstance; threeScene: THREE.Scene; contextDragObjects: THREE.Mesh[]; }) {

    // find index of tab
    let index = this.globalObjectInstance.tabContext.indexOf(tab);
    
    if (index > 0 || (index == 0 && this.globalObjectInstance.tabContext.length > 1)) {
      // remove tab from tabContext
      this.globalObjectInstance.tabContext.splice(index, 1);
      // set selectedTab to index -1
      if(index == 0){
        this.clickedTab(index)
      }
      else{
        this.clickedTab(index - 1);
      }
      this.logger.log('close tab', 'info');
      return;
    }
    if (index == 0 && this.globalObjectInstance.tabContext.length == 1) {
      this.globalObjectInstance.tabContext = [];
      this.globalObjectInstance.scene = new THREE.Scene();
      this.globalObjectInstance.selectedTab = -1;
      this.eventAggregator.publish('tabChanged');
      this.globalObjectInstance.dragObjects = [];
    }
  }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = MainBodyTabBar;

    // @ts-ignore
    const proto = MainBodyTabBar.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }