import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './dialog-loading-window.html';
import { EventAggregator } from "aurelia";

@customElement(__au2ViewDef)
export class DialogLoadingWindow {

    private open = false;
    private buffer = 0;
    private progress = 0;

    constructor(
        private eventAggregator: EventAggregator
    ) { }

    async attached() {
        //subscribe to event aggregator from this -> this.eventAggregator.publish('...', payload);
        this.eventAggregator.subscribe('openDialogLoadingWindow', async payload => { await this.initProgressBar(); });
    }

    async initProgressBar() {
        this.open = true;
        this.updateProgressBar();
    }

    // function to increase the values of the progress and buffer gradually to fool the user
    // the progress bar will get slower the closer it gets to 100%
    async updateProgressBar() {
        let delay = 0;
        if (this.open) {
            if (this.progress < 0.99) { // Adjusted to prevent reaching 100%
                if (this.progress < 0.50) {
                    this.progress += 0.1;
                    this.buffer += 0.1;
                    delay = 500;
                } else if (this.progress < 0.80) {
                    this.progress += 0.05;
                    this.buffer += 0.05;
                    delay = 1000;
                } else {
                    this.progress += 0.02 * (1 - this.progress); // Smaller increment as it approaches 100%
                    this.buffer += 0.02 * (1 - this.progress);
                    delay = 1500;
                }
                setTimeout(() => { this.updateProgressBar(); }, delay);
            } else {
                this.progress = 0.99; // Cap the progress at 99%
                this.buffer = 0.99;
            }
        }
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = DialogLoadingWindow;

    // @ts-ignore
    const proto = DialogLoadingWindow.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }