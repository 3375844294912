import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './camera-toggle.html';
import { GlobalDefinition } from "resources/global_definitions";
import { GlobalStateObject } from "resources/global_state_object";

import { Logger } from 'resources/services/logger';

@customElement(__au2ViewDef)
export class CameraToggle {

    constructor(
        private globalObjectInstance: GlobalDefinition,
        private logger: Logger,
        private globalStateObject: GlobalStateObject
    ) {
    }

    //when the toggle button is clicked the camera is changed from 2d to 3d and vice versa
    //this includes also seperate orbitcontrols for 2d and 3d
    async toggle() {
        this.globalObjectInstance.threeDimensional = !this.globalObjectInstance.threeDimensional;
        this.logger.log('CameraToggle toggle' + this.globalObjectInstance.threeDimensional, 'info')

        //change normal camera to 2d or 3d
        await this.resetView();

        if (!this.globalObjectInstance.threeDimensional) {
            this.globalObjectInstance.normalCamera = this.globalObjectInstance.normalCamera2d;
            this.globalObjectInstance.camera = this.globalObjectInstance.normalCamera;
            this.globalObjectInstance.orbitControls = this.globalObjectInstance.orbitControls2d;
        }
        if (this.globalObjectInstance.threeDimensional) {
            this.globalObjectInstance.normalCamera = this.globalObjectInstance.normalCamera3d;
            this.globalObjectInstance.camera = this.globalObjectInstance.normalCamera;
            this.globalObjectInstance.orbitControls = this.globalObjectInstance.orbitControls3d;
        }


        this.globalObjectInstance.transformControls.camera = this.globalObjectInstance.camera;
        await this.resetView();
    }

    async enableTranslate() {
        this.globalObjectInstance.transformControls.setMode("translate");
    }

    async enableRotate() {
        this.globalObjectInstance.transformControls.setMode("rotate");
    }

    async enableScale() {
        this.globalObjectInstance.transformControls.setMode("scale");
    }

    async resetView() {

        this.globalStateObject.setState(1);
        if (!this.globalObjectInstance.threeDimensional) {
            this.globalObjectInstance.normalCamera2d.position.set(0, 0, 10);
            this.globalObjectInstance.normalCamera2d.zoom = 1;
            this.globalObjectInstance.orbitControls2d.reset();
        }

        if (this.globalObjectInstance.threeDimensional) {
            this.globalObjectInstance.normalCamera3d.position.set(0, 0, 10);
            this.globalObjectInstance.normalCamera3d.zoom = 1;
            this.globalObjectInstance.orbitControls3d.reset();
        }
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = CameraToggle;

    // @ts-ignore
    const proto = CameraToggle.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }