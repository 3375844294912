import { InstanceUtility } from './services/instance_utility';
import { Animator } from './animator';
import { GlobalDefinition } from 'resources/global_definitions';
import { singleton } from 'aurelia';
import { ObjectInstance } from '../../../mmar-global-data-structure';
import * as THREE from 'three';

@singleton()
export class IntervalHandler {



  // constructor(
  //   private globalObjectInstance: GlobalDefinition,
  //   private animator: Animator,
  //   private instanceUtility: InstanceUtility
  // ) {}




}
