<require from="./my-app.scss"></require>
<import from="./views/top-nav-bar/top-nav-bar"></import>
<import from="./views/main-body-tab-bar/main-body-tab-bar"></import>

<div class="menu">
    <top-nav-bar></top-nav-bar>
</div>
<main-body-tab-bar></main-body-tab-bar>

<style>
    body {
        margin: 0;
        padding: 0;
    }

    .menu {
        width: 100vw;
        background-color: #f1f1f1;
        height: 70px;
        padding: 0;
    }

    .flex-container {
        display: flex;
        flex-direction: row;
        height: calc(100vh - 70px - 38px - 48px);
    }

    /* Create three equal columns that floats next to each other */
    .column1 {
        /* Enable vertical scrolling */
        max-width: 330px;
        min-width: 200px;
        width: 20vw;
        border: 1px solid #ccc;

    }

    .column2 {
        /* Enable vertical scrolling */
        /* display: flex;
        flex: 1;
        flex-grow: auto;
        border: 1px solid #ccc; */
        max-width: calc(100vw - 200px - 200px);
        min-width: calc(100vw - 330px - 330px);
        width: 60vw;
        border: 1px solid #ccc;

    }

    .column3 {
        /* Enable vertical scrolling */
        max-width: 330px;
        width: 20vw;
        min-width: 200px;
        border: 1px solid #ccc;

    }

    .tab-bar-horizontal {
        height: 48px;
        padding: 0;
        overflow-x: hidden;
        max-width: calc(100%vh -200px -200px);
        min-width: calc(100vh -330px -230px);

    }

    .scroll-y {
        overflow-y: auto;
    }

    .demo-card {
        width: 45%;
        margin: 2% 2% 2% 2%;
    }

    .mdc-expandable .mdc-expandable__content-container .mdc-expandable__content {
        padding: 0 0px 0px;
    }

    .middle_body {
        width: 100%;
        height: 100%;
    }

    .three_canvas {
        width: 100%;
        height: 100%;
    }

    .attribute {
        width: 100%;
        margin-bottom: 10%;
    }

    .attribute .mdc-text-field {
        width: 100%;
    }

    .vl {
        border-left: 1px solid black;
        height: 100%;
    }

    .logWindow {
        /* Enable vertical scrolling */
        height: 19%;
        border-top: 1px solid #ccc;
        margin: 0;
        padding: 0;
    }

    .block {
        display: block;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .hidescroll::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hidescroll {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .fixed-top {
        position: -webkit-sticky;
        /* For Safari */
        position: sticky;
        top: 0;
        background-color: white;
        /* Optional: background color to cover content behind it */
        z-index: 1;
        /* Optional: adjust the stacking order */
        margin: 0;
        padding: 0;
    }


    /* make the mdc-menu list items as width as needed  */
    .mdc-menu {
        width: auto;
    }
</style>