import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './attribute-window.html';
import { HybridAlgorithmsService } from './../../resources/services/hybrid_algorithms_service';
import { StatechangeAlgorithms } from './../../resources/hybridAlgorithms/statechange_algorithms';
import { MetaUtility } from './../../resources/services/meta_utility';
import { GlobalSelectedObject } from './../../resources/global_selected_object';
import { EventAggregator, bindable } from "aurelia";
import { ClassInstance, PortInstance, RelationclassInstance, AttributeInstance, SceneInstance, AttributeType, Attribute, RoleInstance } from "../../../../mmar-global-data-structure";
import { GlobalDefinition } from 'resources/global_definitions';
import { InstanceUtility } from 'resources/services/instance_utility';
import { Logger } from 'resources/services/logger';
import { VizrepUpdateChecker } from 'resources/services/vizrep_update_checker';
import { GraphicContext } from 'resources/graphic_context';

@customElement(__au2ViewDef)
export class AttributeWindow {

  currentClassInstance: ClassInstance = null;
  currentPortInstance: PortInstance = null;
  currentRelationclasstInstance: RelationclassInstance = null;
  attributeInstances: AttributeInstance[] = [];

  attributeInstancesNoTable: AttributeInstance[] = [];
  //store attribute types for attributeInstancesNoTable array
  attributeTypesForNoTableAttributeInstances: AttributeType[] = [];

  attributeInstanceTable: AttributeInstance[] = [];
  //store attribute types for attributeInstanceTable array
  attributeTypesForTableAttributeInstances: AttributeType[] = [];

  attributeInstancesReferenceAttribute: AttributeInstance[] = [];

  //store attribute types for attributeInstancesReferenceAttribute array
  attributeTypesForReferenceAttributeInstances: AttributeType[] = [];

  oldAttributeValues: { uuid: string, value: any }[] = [];
  visible: boolean = false;

  //boolean for checking dialog level
  @bindable firstLevel: boolean = true;

  constructor(
    private eventAggregator: EventAggregator,
    private globalSelectedObject: GlobalSelectedObject,
    private globalObjectInstance: GlobalDefinition,
    private instanceUtility: InstanceUtility,
    private logger: Logger,
    private metaUtility: MetaUtility,
    private hybridAlgorithmsService: HybridAlgorithmsService,
    private vizrepUpdateChecker: VizrepUpdateChecker,
    private gc: GraphicContext
  ) {
  }

  async attached() {
    this.eventAggregator.subscribe('updateAttributeGui', this.updater.bind(this));
    this.eventAggregator.subscribe('removeAttributeGui', await this.delayedReset.bind(this));
    this.eventAggregator.subscribe('gltfUploaded', async payload => { await this.gltfUploaded(payload) });
    this.eventAggregator.subscribe('imageUploaded', async payload => { await this.imageUploaded(payload) });
  }

  async gltfUploaded(message) {
    this.hybridAlgorithmsService.checkHybridAlgorithms(message);
  }

  async imageUploaded(message) {
    this.hybridAlgorithmsService.checkHybridAlgorithms(message);
  }

  async updater() {
    await this.reset();

    //if there is a selected object
    const selectedObject = this.globalSelectedObject.getObject();
    if (selectedObject) {
      //get classInstance and its AttributeInstances
      let sceneInstance: SceneInstance = await this.instanceUtility.getTabContextSceneInstance();
      this.currentClassInstance = sceneInstance.class_instances.find(class_instance => class_instance.uuid == this.globalSelectedObject.getObject().uuid);
      let portInstances = await this.instanceUtility.getAllPortInstancesOfTabContext();
      this.currentPortInstance = portInstances.find(port_instance => port_instance.uuid == this.globalSelectedObject.getObject().uuid);
      this.currentRelationclasstInstance = sceneInstance.relationclasses_instances.find(relationclass_instance => relationclass_instance.uuid == this.globalSelectedObject.getObject().uuid);

      //if there is a classInstance
      if (this.currentClassInstance) {
        this.attributeInstances = this.currentClassInstance.attribute_instance;
      }
      //if there is a portInstance
      else if (this.currentPortInstance) {
        this.attributeInstances = this.currentPortInstance.attribute_instances;
      }
      //if there is a relationclassInstance
      else if (this.currentRelationclasstInstance) {
        this.attributeInstances = this.currentRelationclasstInstance.attribute_instance;
      }

      //for sorting after sequence number
      const enhancedAttributeInstanceArray = [];
      for (const attributeInstance of this.attributeInstances) {
        //get the uuid to which the attribute belongs to
        let uuidParent = attributeInstance.assigned_uuid_class_instance;
        if (!uuidParent) {
          uuidParent = attributeInstance.assigned_uuid_port_instance;
        }
        if (!uuidParent) {
          uuidParent = attributeInstance.assigned_uuid_scene_instance;
        }

        let metaAttribute: Attribute

        //get instance concept of uuidParent
        if (uuidParent) {
          let classInstance = await this.instanceUtility.getClassInstance(uuidParent);
          let portInstance = await this.instanceUtility.getPortInstance(uuidParent);
          let sceneInstance = await this.instanceUtility.getSceneInstance(uuidParent);
          if (classInstance) {
            classInstance ? metaAttribute = await this.metaUtility.getMetaAttributeWithSequence(attributeInstance.uuid_attribute, classInstance.uuid_class) : undefined;
          }
          else if (!classInstance) {
            portInstance ? metaAttribute = await this.metaUtility.getMetaAttributeWithSequence(attributeInstance.uuid_attribute, portInstance.uuid_port) : undefined;
          }
          else if (!classInstance && !portInstance) {
            sceneInstance ? metaAttribute = await this.metaUtility.getMetaAttributeWithSequence(attributeInstance.uuid_attribute, sceneInstance.uuid_scene_type) : undefined;
          }
        }

        //if sequence is not set in meta attribute, set it to 1000, otherwiseset value
        const sequence = metaAttribute.sequence ?? 1000;
        const uiComponent = metaAttribute.ui_component ?? "text";

        //get enum values from regex if attribute type is enum or boolean
        let facets: string[] = [];
        let facetsString: string = "";
        if (metaAttribute.attribute_type.regex_value) {
          facetsString = metaAttribute.facets;
        }
        if (facetsString) {
          //split regex at | and pus each value to array
          facets = facetsString.split("|");
        }

        enhancedAttributeInstanceArray.push(
          {
            "attributeInstance": attributeInstance,
            "sequence": sequence,
            "uiType": uiComponent,
            "metaAttribute": metaAttribute,
            "facets": facets
          }
        );
      }

      //sort array after sequence number
      enhancedAttributeInstanceArray.sort((a, b) => a.sequence - b.sequence);
      this.attributeInstances = [];
      for (const sequenceObject of enhancedAttributeInstanceArray) {
        this.attributeInstances.push(sequenceObject.attributeInstance);
      }



      //set current values of each instance to an array --> needed for text mesh update
      for (const attributeInstanceFromArray of enhancedAttributeInstanceArray) {
        //push all old values
        this.oldAttributeValues.push(
          {
            "uuid": attributeInstanceFromArray.attributeInstance.uuid,
            "value": attributeInstanceFromArray.attributeInstance.value
          }
        );

        //get meta attribute of attribute instance
        const metaAttribute: Attribute = await this.metaUtility.getMetaAttribute(attributeInstanceFromArray.attributeInstance.uuid_attribute);
        let attributeType: AttributeType;
        let isReferenceAttribute: boolean = false;
        if (metaAttribute) {
          attributeType = metaAttribute.attribute_type;
          //check if attribute is a reference attribute
          //attribute.role is set
          isReferenceAttribute = attributeType.role != null;
        }

        if (isReferenceAttribute) {
          this.attributeInstancesReferenceAttribute.push(attributeInstanceFromArray);
          this.attributeTypesForReferenceAttributeInstances.push(attributeType);
        }
        //push no table attribute instances to array
        else if (attributeInstanceFromArray.attributeInstance.table_attributes.length == 0) {
          this.visible = true;
          this.attributeInstancesNoTable.push(attributeInstanceFromArray);
          this.attributeTypesForNoTableAttributeInstances.push(attributeType);
        }
        //push table attribute table instances to array
        else {
          this.visible = true;
          this.attributeInstanceTable.push(attributeInstanceFromArray);
          this.attributeTypesForTableAttributeInstances.push(attributeType);
        }
      };
    }
  }

  async reset() {
    this.currentClassInstance = null;
    this.currentPortInstance = null;
    this.attributeInstances = [];
    this.attributeInstancesNoTable = [];
    this.attributeInstanceTable = [];
    this.attributeInstancesReferenceAttribute = [];
    this.attributeTypesForNoTableAttributeInstances = [];
    this.attributeTypesForTableAttributeInstances = [];
    this.attributeTypesForReferenceAttributeInstances = [];
    this.visible = false;
    this.oldAttributeValues = [];
  }

  //reset with delay of 100ms -> needed for text mesh update since we need the context of the old values
  async delayedReset() {
    setTimeout(async () => {
      await this.reset();
    }, 10);
  }

  //Update text mesh -> called from view
  // updateTextMesh(attributeInstance: AttributeInstance) {
  //   const oldValue: { uuid: string, value: any } = this.oldAttributeValues.find((oldValue: { uuid: string, value: any }) => oldValue.uuid == attributeInstance.uuid);
  //   let textMesh: any = undefined;

  //   if (this.currentClassInstance) {
  //     textMesh = this.globalObjectInstance.dragObjects.find(textMesh =>
  //       textMesh.name == oldValue.value + textMesh.uuid
  //       && textMesh.parent.uuid == this.currentClassInstance.uuid);
  //   } else if (this.currentPortInstance) {
  //     textMesh = this.globalObjectInstance.dragObjects.find(textMesh =>
  //       textMesh.name == oldValue.value + textMesh.uuid
  //       && textMesh.parent.uuid == this.currentPortInstance.uuid);
  //   }

  //   //-----------------------------------
  //   if (textMesh) {
  //     textMesh.text = attributeInstance.value;
  //     textMesh.name = attributeInstance.value + textMesh.uuid;
  //     oldValue["value"] = attributeInstance.value;

  //     // pus to log file
  //     this.logger.log('Attribute ' + oldValue["value"] + ' changed to ' + attributeInstance.value + '!', 'done');
  //   }
  // }


  async fieldChange(attributeInstance) {

    //update attribute value
    attributeInstance.value = attributeInstance.value.toString();
    //this.updateTextMesh(attributeInstance);

    await this.vizrepUpdateChecker.checkForVizRepUpdate(attributeInstance);

    //if this.currentClassInstance is set, we are in a classInstance
    if (this.currentClassInstance) {
      await this.hybridAlgorithmsService.checkHybridAlgorithms(null, [this.currentClassInstance]);
    }
    //if this.currentPortInstance is set, we are in a portInstance
    else if (this.currentPortInstance) {
      await this.hybridAlgorithmsService.checkHybridAlgorithms(null, null,[this.currentPortInstance]);
    }
  }

  // todo -> add roleinstance name to other reference fields


  //this function handles the dialog used for reference fields. Since the dialog is used multiple times, it is called here with the right context
  async openDialog(dialog, attributeInstance) {
    console.log("open dialog");
    this.eventAggregator.publish('openReferenceDialog', { attributeInstance: attributeInstance });
    dialog.open(attributeInstance)
  }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = AttributeWindow;

    // @ts-ignore
    const proto = AttributeWindow.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }