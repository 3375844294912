import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './dialog-algorithm.html';
import { EventAggregator } from "aurelia";
import { Procedure } from "../../../../mmar-global-data-structure";
import { GlobalDefinition } from "resources/global_definitions";
import { ProcedureUtility } from "resources/services/procedure_utility";

@customElement(__au2ViewDef)
/**
 * Represents a dialog for selecting and executing algorithms.
 */
export class DialogAlgorithm {
    procedures: Procedure[] = [];
    procedureNames: String[] = [];
    assignedProcedureNames: String[] = [];
    independentAlgorithmChoice: string;
    dependentAlgorithmChoice: string;
    assignedProcedures: Procedure[] = [];
    sceneTypeName: string;

    /**
     * Constructs a new instance of the DialogAlgorithm class.
     * @param eventAggregator - The event aggregator used for subscribing to events.
     * @param globalObjectInstance - The global object instance.
     * @param procedureUtility - The utility class for working with procedures.
     */
    constructor(
        private eventAggregator: EventAggregator,
        private globalObjectInstance: GlobalDefinition,
        private procedureUtility: ProcedureUtility,
    ) {
    }

    /**
     * Attaches the dialog to the DOM.
     */
    async attached() {
        this.eventAggregator.subscribe("openAlgorithmDialog", async () => {
            await this.getProcedures();
        });
    }

    /**
     * Retrieves the procedures from the database.
     */
    async getProcedures() {
        this.procedures = await this.procedureUtility.getGeneralProcedures();
        if (this.globalObjectInstance.tabContext[this.globalObjectInstance.selectedTab] != undefined) {
            this.assignedProcedures = await this.procedureUtility.getAssignedProcedures();
            this.sceneTypeName = this.globalObjectInstance.tabContext[this.globalObjectInstance.selectedTab].sceneType.get_name();
        }
        await this.getProcedureNames();
    }

    /**
     * Retrieves the names of the procedures.
     */
    async getProcedureNames() {
        this.procedureNames = [];
        this.assignedProcedureNames = [];
        for (let i in this.procedures) {
            this.procedureNames.push(this.procedures[i].name);
        }
        for (let j in this.assignedProcedures) {
            this.assignedProcedureNames.push(this.assignedProcedures[j].name);
        }
    }

    /**
     * Executes the dependent algorithm.
     */
    async executeDependent() {
        await this.procedureUtility.execute("", this.dependentAlgorithmChoice);
    }

    /**
     * Executes the independent algorithm.
     */
    async executeIndependent() {
        await this.procedureUtility.execute(this.independentAlgorithmChoice, "");
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = DialogAlgorithm;

    // @ts-ignore
    const proto = DialogAlgorithm.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }