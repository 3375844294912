import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './dialog-save-as.html';
import { GlobalDefinition } from "resources/global_definitions";
import { SceneInstance } from "../../../../mmar-global-data-structure";
import { Logger } from "resources/services/logger";
import { PersistencyHandler } from "resources/persistency_handler";
import { InstanceUtility } from "resources/services/instance_utility";
import { EventAggregator } from "aurelia";

@customElement(__au2ViewDef)
export class DialogSaveAs {

    sceneInstance: SceneInstance;
    tabContext;

    constructor(
        private globalObjectInstance: GlobalDefinition,
        private logger: Logger,
        private persistencyHandler: PersistencyHandler,
        private instanceUtility: InstanceUtility,
        private eventAggregator: EventAggregator
    ) {
        this.tabContext = this.globalObjectInstance.tabContext;
    }

    async attached() {
        this.eventAggregator.subscribe("openDialogSaveAs", async () => {
            await this.init();
        });
    }

    init() {
        this.sceneInstance = this.tabContext[this.globalObjectInstance.selectedTab]["sceneInstance"] as SceneInstance;
        return ''
    }

    getCoordinates3D() {
        return JSON.stringify(this.sceneInstance.relative_coordinate_3d);
    }

    getAbsoluteCoordinates3D() {
        return JSON.stringify(this.sceneInstance.absolute_coordinate_3d);
    }

    getCustomVariables() {
        return JSON.stringify(this.sceneInstance.custom_variables);
    }

    async saveToText() {
        this.logger.log('saveToText button clicked', 'info');
        await this.persistencyHandler.saveToTextfile();
    }

    async saveToDatabase() {
        this.logger.log('saveToDatabase button clicked', 'info');
        await this.persistencyHandler.persistSceneInstanceToDB()
    }

    cancel() {
        this.logger.log('cancel button clicked', 'close');
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = DialogSaveAs;

    // @ts-ignore
    const proto = DialogSaveAs.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }