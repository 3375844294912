import { Animator } from './animator';
import { singleton } from 'aurelia';
import * as THREE from 'three';
import { GlobalDefinition } from './global_definitions';
import { Logger } from './services/logger';


@singleton()
export class ArInitiator {

  constructor(
    private globalObjectInstance: GlobalDefinition,
    private animator: Animator,
    private logger: Logger
  ) {
  }



  // for AR additional support, e.g., image-tracking
  // compare https://github.com/ShirinStar/webAR_experiments/tree/main/16-webxr-image_tracking
  render(timestamp, frame) {

    this.animator.animate();

  }
  //AR events
  onSessionStarted() {
    this.globalObjectInstance.camera = this.globalObjectInstance.ARCamera;
    this.globalObjectInstance.render = false;
    this.logger.log('ar camera active', 'info' )
  }

  onSessionEnded() {
    this.globalObjectInstance.camera = this.globalObjectInstance.normalCamera;
    this.globalObjectInstance.render = true;
    this.logger.log('normal camera active', 'info')

  }
}
