import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './three-canvas.html';
import { HybridAlgorithmsService } from './../../resources/services/hybrid_algorithms_service';
import { KeyboardHandler } from './../../resources/keyboard_handler';
import { MathUtility } from './../../resources/services/math_utility';
import { InstanceUtility } from './../../resources/services/instance_utility';
import { IntervalHandler } from './../../resources/interval_handler';
import { GlobalDefinition } from 'resources/global_definitions';
import * as THREE from 'three';
import { Animator } from 'resources/animator';
import { ObjectInstance } from '../../../../mmar-global-data-structure';
import { Logger } from 'resources/services/logger';

@customElement(__au2ViewDef)
export class ThreeCanvas {

  // we inject here the keyboardHandler functions
  // do not delete
  constructor(
    private globalObjectInstance: GlobalDefinition,
    private instanceUtility: InstanceUtility,
    private animator: Animator,
    private mathUtility: MathUtility,
    private keyboardHandler: KeyboardHandler,
    private logger: Logger,
    private hybridAlgorithmsService: HybridAlgorithmsService,
  ) {
  }

  attached() {
    //get html element by id
    let element = document.getElementById('container');
    this.globalObjectInstance.elementContainer = element;

    // set steady rendering at least every second
    //set intervall
    setInterval(() => {
      this.globalObjectInstance.render = true;
    }, 1000);

    //chech every 10 second if class_instance metafunction has changed and update representation if necessary
    setInterval(() => {
      if (this.globalObjectInstance.camera == this.globalObjectInstance.normalCamera) {
        //we could automatically update the geometries if there is a change at runtime of the metamodel geometry
        //updateClassGeometries
      }
    }, 10000);

    //check for update of positions in class_instance according to the position of the object in the threejs scene
    setInterval(() => {
      if (this.globalObjectInstance.tabContext.length > 0) {
        // this.updateCoordinates2DonClassAndPortInstance();
        // this.updateRotationOnClassAndPortInstance();
        this.updateHybridAlgorithmAttributes();
      }
    }, 1000);
  }

// this triggers the check for updating the attribute values of the references of a Statechange SceneInstance.
// this is not generic but only for one SceneType
  async updateHybridAlgorithmAttributes() {
    this.hybridAlgorithmsService.updateHybridAlgorithmAttributes();
  }

}



    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = ThreeCanvas;

    // @ts-ignore
    const proto = ThreeCanvas.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }