import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './dialog-upload-gltf.html';
import { EventAggregator } from "aurelia";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { plainToInstance } from "class-transformer";
import { AttributeInstance, SceneInstance, SceneType } from "../../../../mmar-global-data-structure";
import { GlobalDefinition } from "resources/global_definitions";
import { bindable } from "aurelia";
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

@customElement(__au2ViewDef)
export class DialogUploadGltf {
    private uppy: Uppy;
    @bindable private attributeInstance: AttributeInstance
    @bindable private firstLevel: boolean = null;

    constructor(
        private eventAggregator: EventAggregator,
    ) {
    }

    async attached() {
        //init uppy dashboard
        //only allow gltf files
        this.uppy = new Uppy(
            {
                restrictions: {
                    allowedFileTypes: ['.gltf']
                }
            }
        );

        //bind dashboard to the dragdrop element
        //if first level, bind to the first dragdrop element
        //if second level, bind to the second dragdrop element
        // we have to do this because the dragdrop element would be rendered twice otherwise
        if (this.firstLevel)
            this.uppy.use(Dashboard, { inline: true, target: '#dragdropfirstlevel', hideUploadButton: true, showProgressDetails: true, width: '100%', height: '200px' });
        else {
            this.uppy.use(Dashboard, { inline: true, target: '#dragdropsecondlevel', hideUploadButton: true, showProgressDetails: true, width: '100%', height: '200px' });
        }
    }

    load() {
        const files = this.uppy.getFiles();
        const reader = new FileReader();

        if (files) {
            for (const file of files) {
                //read the file -> trigger the event listener
                reader.readAsText(file.data);
                //remove the file from the uppy dashboard
                this.uppy.removeFile(file.id)
            }
            //timeout to wait for the files to be read
            setTimeout(async () => {
                //publish the event to update the scene group
                this.eventAggregator.publish('gltfUploaded', this.attributeInstance);
            }, 1000);
        }

        //event listener for when the file is loaded
        reader.addEventListener(
            "load",
            () => {
                //when a file is loaded, convert it to string
                const string = reader.result as string;
                this.attributeInstance.value = string;
            },
            { passive: true }
        );
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = DialogUploadGltf;

    // @ts-ignore
    const proto = DialogUploadGltf.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }