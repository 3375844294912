import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './top-nav-bar.html';
import { bindable } from "aurelia";
import { DialogHelper } from "resources/dialog_helper";

@customElement(__au2ViewDef)
export class TopNavBar {

   @bindable dialogSaveAs = null;

   constructor(
      //used directly in html
      private dialogHelper: DialogHelper
   ) { }

   fileMenu = {
      name: "File",
      icon: "folder",
      items: [
         {
            label: "Save Model",
            icon: "save",
            disabled: false,
            dialogName: "dialogSaveAs",
            eventPropagationName: "openDialogSaveAs"
         },
         {
            label: "Export Model as .txt",
            icon: "folder_special",
            disabled: false
         },
         {
            label: "Import Model",
            icon: "upload",
            disabled: false,
            dialogName: "dialogImportModel",
            eventPropagationName: "openDialogImportModel"
         },
         {
            label: "Import Metamodel",
            icon: "upload",
            disabled: false,
            dialogName: "dialogImportMetamodel",
            eventPropagationName: "openDialogImportMetamodel"
         },
         {
            label: "Export Open Models",
            icon: "folder_special",
            disabled: false
         }
      ],
      open: true
   };

   viewMenu = {
      name: "View",
      icon: "visibility",
      items: [
         {
            label: "Zoom In",
            icon: "zoom_in",
            disabled: true
         },
         {
            label: "Zoom Out",
            icon: "zoom_out",
            disabled: true
         },
         {
            label: "Zoom to Fit",
            icon: "zoom_out_map",
            disabled: true
         },
         {
            label: "Zoom to Selection",
            icon: "center_focus_strong",
            disabled: true
         }
      ],
      open: false
   }


   editMenu =
      {
         name: "Edit",
         icon: "edit",
         items: [
            {
               label: "Undo",
               icon: "undo",
               disabled: true
            },
            {
               label: "Redo",
               icon: "redo",
               disabled: true
            },
            {
               label: "Copy",
               icon: "file_copy",
               disabled: true
            },
            {
               label: "Paste",
               icon: "content_paste",
               disabled: true
            },
            {
               label: "Cut",
               icon: "content_cut",
               disabled: true
            }
         ],
         open: false
      };

   diagramMenu = {
      name: "Diagram",
      icon: "schema",
      items: [
         {
            label: "Check Rule Current Object",
            icon: "rule",
            disabled: true
         },
         {
            label: "Check Rule For Model",
            icon: "rule_folder",
            disabled: true
         },
         {
            label: "...",
            icon: "folder",
            disabled: true
         },
      ],
      open: false
   };

   settingsMenu = {
      name: "Settings",
      icon: "settings",
      items: [
         {
            label: "Open Settings",
            icon: "toggle_on",
            disabled: true
         },
      ],
      open: false
   };
}

    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = TopNavBar;

    // @ts-ignore
    const proto = TopNavBar.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }