import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './dialog-import-model.html';
import { EventAggregator } from "aurelia";


import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
// import '@uppy/drag-drop/dist/style.min.css';

import { plainToInstance } from "class-transformer";
import { SceneInstance } from "../../../../mmar-global-data-structure";
import { GlobalDefinition } from "resources/global_definitions";
import { Logger } from "resources/services/logger";

@customElement(__au2ViewDef)
export class DialogImportModel {
    private uppy: Uppy;

    constructor(
        private eventAggregator: EventAggregator,
        private globalObjectInstance: GlobalDefinition,
        private logger: Logger,
    ) {
        this.eventAggregator.subscribe('openDialogImportModel', async () => { await this.open() });
    }


    async open() {

        //init uppy dashboard
        //only allow json files
        this.uppy = new Uppy(
            {
                restrictions: {
                    allowedFileTypes: ['.json']
                }
            }
        );

        //bind dashboard to the dragdrop element
        this.uppy.use(Dashboard, { inline: true, replaceTargetContent: true, target: '#dragdropModel', hideUploadButton: true, showProgressDetails: true, width: '100%', height: '200px' });
    }

    loadLocal() {
        const files = this.uppy.getFiles();


        if (files) {
            for (const file of files) {
                const reader = new FileReader();
                //read the file -> trigger the event listener
                reader.readAsDataURL(file.data);
                //remove the file from the uppy dashboard
                this.uppy.removeFile(file.id)

                //event listener for when the file is loaded
                reader.addEventListener(
                    "load",
                    () => {
                        //when a file is loaded, convert it to JSON string
                        const string = reader.result as string;
                        const json = atob(string.substring(29));
                        const result = JSON.parse(json);

                        //convert the json to a SceneType object
                        const sceneInstance = plainToInstance(SceneInstance, result);
                        this.globalObjectInstance.importSceneInstances.push(sceneInstance);
                        this.logger.log("sceneInstance: " + sceneInstance.uuid + " pushed to importSceneInstances", "info");
                        this.logger.log("importSceneInstances: " + this.globalObjectInstance.importSceneInstances, "info");
                    },
                    { passive: true }
                );

            }
            //timeout to wait for the files to be read
            setTimeout(() => {
                //publish the event to update the scene group
                this.eventAggregator.publish('updateSceneGroup');
                this.uppy.close();
            }, 1000);
        }
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = DialogImportModel;

    // @ts-ignore
    const proto = DialogImportModel.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }